<template>
  <div class="brands">
    <BrandsInfo :data="BrandsInfoData" />
    <div class="bg-white choose">
      <h5 class="pt-3 fw-bold">選擇品牌</h5>
      <div class="d-flex p-3" style="height: 110px; overflow: auto">
        <div
          class="mx-lg-5 mx-3 choose_bar"
          :class="{ clicked_opacity: item.clicked }"
          :style="{
            background:
              'url(' + item.data.logo_url + ')no-repeat center/contain',
          }"
          v-for="(item, key) in allBrands"
          :key="key"
          @click="clicked_brand(item)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import BrandsInfo from "@/components/BrandsInfo";

export default {
  components: {
    BrandsInfo,
  },
  data() {
    return {
      BrandsInfoData: null,
      allBrands: [
        {
          clicked: false,
          name: "aaron",
          data: {
            logo_url: require("../assets/brands/logo_aron.png"),
            title: "阿榮嚴選",
            content:
              "由陳昭榮一手打造的直播互動購物平台，嚴格挑選各類生鮮、肉品、水產、水果、熟食好物，優質的客服滿足消費者對高CP值商品的需求，專業短影音直播團隊完美呈現廠商對產品銷售的託付，達到阿榮嚴選、消費者、廠商三方共贏良性供需循環。",
            website: "https://aaron.com.tw/",
            facebook: "https://www.facebook.com/Hanchu0800588880",
            instagram: "https://www.instagram.com/aaron_select/",
            image: require("../assets/brands/mobile_aron.png"),
          },
        },
        {
          clicked: false,
          name: "hiyuoguo",
          data: {
            logo_url: require("../assets/brands/logo_hiyuoguo.png"),
            title: "嗨攸果",
            content:
              "水果富含優質營養素甜、酸的天然味讓人總想再多吃一口。世界各國珍品、台灣在地好味進貨管道多元且豐富，加上多年銷售水果經驗，嚴格挑選最佳品嘗時期美味鮮果，是您購買自用、送禮餽贈、企業團購的絕佳平台。",
            website: "",
            facebook: "https://www.facebook.com/hiyuoguo.tw",
            instagram: "https://instagram.com/hiyuoguo.tw?utm_medium=copy_link",
            image: require("../assets/brands/admin-ajax.png"),
          },
        },
        {
          clicked: false,
          name: "nippi",
          data: {
            logo_url: require("../assets/brands/logo_nippi.png"),
            title: "Nippi",
            content:
              "翰成數位科技創始人陳昭榮以客戶對健康的需求做出發，一個月一封信用真誠與直播實力獲得日本百年大廠NIPPI青睞，成為將日本膠原蛋白龍頭NIPPI獨家引進到台灣的第一人。世界第一的日本NIPPI膠原蛋白，無臭、無味、無添加讓您吃下的每一口都是100%純膠原蛋白",
            website: "https://aaron.com.tw/?type=NIPPI",
            facebook: "https://www.facebook.com/NIPPIHANCHU",
            instagram:
              "https://instagram.com/nippi_taiwan?utm_medium=copy_link",
            image: require("../assets/brands/NIPPI商品去背圖-大包裝側-沒有影子.png"),
          },
        },
        {
          clicked: false,
          name: "hcpure",
          data: {
            logo_url: require("../assets/brands/logo_hcpure.png"),
            title: "HC Health",
            content:
              "HC健康以單純、健康、好生活為方向~用最嚴謹的態度研發產品，只有自己敢天天吃的產品才能推薦給大家!我們以永續經營的理念來推出最適合台灣人的產品!單純無添加是我們的經營理念~",
            website: "https://aaron.com.tw/?type=HC400",
            facebook: "https://www.facebook.com/hanchuhealth",
            instagram:
              "https://www.instagram.com/hc_healthy/?utm_medium=copy_link",
            image: require("../assets/brands/HC400-網頁商品圖單盒-2048x2048-2.png"),
          },
        },
        {
          clicked: false,
          name: "hcbeauty",
          data: {
            logo_url: require("../assets/brands/logo_hcbeauty.png"),
            title: "HC Health",
            content:
              "找回保養的初心<br>我用過無數的面膜，我了解肌膚最需要的是什麼其實很簡單、把 保濕 做到最好最極致。只要 保濕 做的好，皮膚狀態一定好肌膚自然就會呈現由裡到外明亮自信光彩。 ",
            website: "https://www.hcbeauty.com.tw/",
            facebook: "https://www.facebook.com/Hc%E7%BE%8E-106833764838933",
            instagram: "https://www.instagram.com/hc_finebeauty/",
            image: require("../assets/brands/PC029671-1.png"),
          },
        },
        {
          clicked: false,
          name: "hischool",
          data: {
            logo_url: require("../assets/brands/logo_hischool.png"),
            title: "嗨學院",
            content:
              "翰成數位科技成立《嗨學院》，運用2005年成立以來即深耕於數位內容發展創新，加上自2017年以來每年產出至少1000小時直播經驗，以頂尖且領先業界的直播、內容、技術、硬體，協助各行各業在內訓、行銷、自媒體發展上透過短影音跟直播，解決數位痛點，豐沛數位量能再創佳績。",
            website: "https://www.hi8school.com/",
            facebook: "https://www.facebook.com/hi8school",
            instagram: "https://www.instagram.com/hi8school/",
            image: require("../assets/brands/mobile_aron-1.png"),
          },
        },
        {
          clicked: false,
          name: "hi8",
          data: {
            logo_url: require("../assets/brands/logo_hibar.png"),
            title: "嗨吧",
            content:
              "有趣好玩想「嗨」就嗨！一滑上手欲「吧」不能！ 在 嗨吧 APP裡你可以創造各種可能，找到最吸睛、歡愉的娛樂，聆聽還有自創動人音樂，智慧推薦一鍵即時購物，無限商機有想法就成交，新聞資訊不錯過精準剖析更精彩，點讚、分享、影音交友讓社交互動零距離，資訊大爆炸懂搜尋你就是知識家！ 秀你想秀、看你想看！打開嗨吧時時精彩天天嗨！",
            website: "https://www.hi8.tw/",
            facebook: "https://www.facebook.com/hi8community",
            instagram: "https://www.instagram.com/hi8community/",
            image: require("../assets/brands/Untitled.png"),
          },
        },
        {
          clicked: false,
          name: "inscrush",
          data: {
            logo_url: require("../assets/brands/logo_inscrush.png"),
            title: "inscrush",
            content: `
            ＝品牌故事＝<br>
            生活在浮躁高壓的水泥森林，睡眼惺忪的上班路，枯燥無味的通勤，高壓緊張的工作，<br>
            飢腸轆轆的回家路組成了你的一天，你是否還記得藍色的天空，綠色的草地，還有白雲底下那個美麗的你。。。<br>
            如果你不能給生活顏色，生活拿什麼給你彩虹？<br>
            愛情是紅色的，親情是藍色的，友情是綠色的，工作是橙色的。。。。<br>
            來吧，我們一起集齊七彩色，向著彩虹的方向，出發。。。。<br>
            <br>
            當你在Instagram欣賞別人的精彩時，眼神和嘴巴已經出賣了你。<br>
            現在，請收起你Crush的眼神<br>
            INSCRUSH主張用簡單的方式回歸生活的本質，用素面材質加微剪裁設計的流行風尚，帶給你輕鬆自在的搭配<br>
            <br>
            簡約不簡單，舒適且時尚<br>
            <br>
            ＝品牌精神＝<br>
            INSCRUSH傳遞一種自信穿搭的態度，<br>
            不盲目追隨流行，而是引領審美風格呈現自我的美感，提供別具風格且易搭配的單品，時而都會優雅，時而街頭率性，<br>
            女人的美不應僅僅是一種風格的呈現，而是更多元的面向呈現。`,
            website: "https://www.inscrush.com/",
            facebook: "https://www.facebook.com/inscrush.tw",
            instagram: "https://www.instagram.com/inscrush_/",
            image: "",
          },
        },
      ],
    };
  },
  created() {
    //載入後預設選擇第一筆資料
    this.clicked_brand(this.allBrands[0]);
  },
  methods: {
    clicked_brand: function (item) {
      this.allBrands.forEach((item) => {
        //將所有的clicked false 確保之中只有一個clicked true
        item.clicked = false;
      });
      item.clicked = true;
      this.BrandsInfoData = item.data;
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss">
.clicked_opacity {
  opacity: 0.3;
}
.d-flex::-webkit-scrollbar {
  border-radius: 50%;
  height: 0.8rem;
}
.d-flex::-webkit-scrollbar-thumb {
  background-color: #2575ed;
}
.choose_bar {
  width: 100%;
  cursor: pointer;
  min-width: 150px;
}
@media screen and (max-width: 989px) {
  .choose_bar {
    min-width: 80px;
  }
}
</style>
