<template>
  <div class="d-flex">
    <b-form-input
      style="margin-right: 1rem"
      type="text"
      maxLength="4"
      v-model="verify_input"
      @change="verify_check()"
    ></b-form-input>
    <canvas id="canvas" width="120" height="40" @click="drawPic()"></canvas>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      code: "",
      verify_input: "",
    };
  },
  mounted() {
    this.drawPic();
  },
  methods: {
    verify_check: function () {
      let vm = this;
      let val = vm.verify_input.toUpperCase();
      if (val == vm.code) {
        vm.$emit("verifyResult", true);
      } else {
        vm.verify_input = "";
        vm.$emit("verifyResult", false);
      }
    },
    drawPic: function () {
      let vm = this;
      function randomNum(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      }

      function randomColor(min, max) {
        let r = randomNum(min, max);
        let g = randomNum(min, max);
        let b = randomNum(min, max);
        return "rgb(" + r + "," + g + "," + b + ")";
      }

      let canvas = document.getElementById("canvas");
      let width = canvas.width;
      let height = canvas.height;
      let ctx = canvas.getContext("2d");

      ctx.textBaseline = "bottom";
      ctx.fillStyle = randomColor(240, 240);
      ctx.fillRect(0, 0, width, height);
      let str = "123456789";
      vm.code = "";
      for (let i = 0; i < 4; i++) {
        let txt = str[randomNum(0, str.length)];

        vm.code += txt;

        ctx.fillStyle = randomColor(50, 160);
        ctx.font = randomNum(30, 35) + "px SimHei";

        let x = 15 + i * 25;
        let y = randomNum(30, 35);
        let deg = randomNum(-45, 45);
        ctx.translate(x, y);
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(txt, 0, 0);
        ctx.rotate((-deg * Math.PI) / 180);
        ctx.translate(-x, -y);
      }

      for (let i = 0; i < 2; i++) {
        ctx.strokeStyle = randomColor(40, 180);
        ctx.beginPath();
        ctx.moveTo(randomNum(0, width), randomNum(0, height));
        ctx.lineTo(randomNum(0, width), randomNum(0, height));
        ctx.stroke();
      }

      for (let i = 0; i < 50; i++) {
        ctx.fillStyle = randomColor(0, 255);
        ctx.beginPath();
        ctx.arc(randomNum(0, width), randomNum(0, height), 1, 0, 2 * Math.PI);
        ctx.fill();
      }
    },
  },
};
</script>
<style lang="scss"></style>
