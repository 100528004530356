<template>
  <div class="row col-9 mx-auto p-2 pb-0" style="min-height: 700px">
    <div class="col-lg-6 col-12 mx-auto text-start my-5">
      <div
        style="max-width: 170px; height: 100px"
        :style="{
          background: 'url(' + data.logo_url + ')no-repeat center/contain',
        }"
      ></div>
      <h3 class="my-3 fw-bold">{{ data.title }}</h3>
      <div style="color: #8d8d8d">
        <p v-html="data.content"></p>
        <div v-if="data.website">
          <p>更多訊息，請訪問：</p>
          <p>
            <a :href="data.website" target="_blank">{{ data.title }}官方網站</a>
          </p>
        </div>
        <div>
          <p>或至官方社群網站：</p>
          <p>
            <a :href="data.facebook" target="_blank">
              <i class="fab fa-facebook fa-3x mx-2" style="color: #3b5998"></i>
            </a>
            <a :href="data.instagram" target="_blank">
              <i class="fab fa-instagram fa-3x mx-2" style="color: black"></i>
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12 mx-auto mt-5 brand_image">
      <img :src="data.image" class="w-100" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.brands a,
.brands a:hover {
  color: blue;
}
.brand_image img {
  max-width: 400px;
}
@media screen and (max-width: 989px) {
  .brand_image img {
    max-width: 250px;
  }
}
</style>
