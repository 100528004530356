<template>
  <div class="contact">
    <div style="height: 500px">
      <div class="banner_bg"></div>
    </div>
    <section class="bg-white py-3">
      <div class="row col-9 col-lg-8 mx-auto text-start">
        <b class="fw-bold">欲洽談業務：</b>
        <div class="row">
          <div
            class="col-6 col-lg my-1"
            v-for="(item, key) in buttons"
            :key="key"
          >
            <b-button
              class="w-100"
              variant="outline-primary"
              :class="{ clicked: item.clicked }"
              @click="clickType(item)"
              >{{ item.name }}</b-button
            >
          </div>
          <div
            class="col-6 col-lg my-1"
          >
          <a href="/eventRegistration" target="_blank">
            <b-button
                class="w-100"
                variant="outline-primary"
                >    嗨吧商城招商        
            </b-button>
          </a>
           
          </div>
        </div>
        <div class="mt-3">{{ show_children.parentName }}</div>
        <div class="row">
          <div
            class="col-12 col-lg-3 my-1"
            v-for="(item, key) in show_children.item"
            :key="key"
          >
            <b-button
              class="w-100"
              variant="outline-primary"
              :class="{ clicked: show_children.clicked === item }"
              @click="show_children.clicked = item"
              >{{ item }}</b-button
            >
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white py-3">
      <div class="row col-9 col-lg-8 mx-auto text-start">
        <hr />
        <b class="my-2 col-12 col-lg-6">
          聯絡人<span class="text-danger">*</span>
          <b-form-input v-model="form.name"></b-form-input>
        </b>
        <b class="my-2 col-12 col-lg-6">
          聯絡電話<span class="text-danger">*</span>
          <b-form-input
            v-model="form.tel"
            @keyup="replaceText()"
            maxLength="10"
          ></b-form-input>
        </b>
        <b class="my-2 col-12">
          E - mail<span class="text-danger">*</span>
          <b-form-input v-model="form.email" type="email"></b-form-input>
        </b>
        <b class="my-2 col-12">
          需求說明
          <b-form-textarea
            id="textarea"
            v-model="form.purpose"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b>
        <b class="my-2 col-12 col-lg-6">
          驗證碼
          <Captcha @verifyResult="get_verify_result" />
        </b>
        <div class="my-2 col-12 text-end">
          <b-button variant="outline-primary" class="w-50" @click="send_form()"
            >確定送出</b-button
          >
        </div>
      </div>
    </section>
    <div class="row col-9 col-lg-8 mx-auto">
      <div class="col-12 col-lg-6 m-auto py-5 text-start">
        <h3 class="fw-bold">翰成數位科技股份有限公司</h3>
        <div class="row">
          <div class="col-3 my-2">地址</div>
          <div class="col my-2">台北市內湖區民權東路六段15巷25號4F</div>
        </div>
        <div class="row">
          <div class="col-3 my-2">營業時間</div>
          <div class="col my-2">週一至週五 09:00-18:00</div>
        </div>
        <div class="row">
          <div class="col-3 my-2">客服電話</div>
          <div class="col my-2">02-77309659</div>
        </div>
        <div class="row">
          <div class="col-3 my-2">E-mail</div>
          <div class="col my-2">service@hanchu.com</div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mx-auto">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.9845542392413!2d121.57633771537914!3d25.06851264306026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ac7ee723035d%3A0x7ef6c5e98da5c022!2zMTE05Y-w54Gj5Y-w5YyX5biC5YWn5rmW5Y2A5rCR5qyK5p2x6Lev5YWt5q61MTXlt7cyNeiZnw!5e0!3m2!1szh-TW!2sus!4v1643006639469!5m2!1szh-TW!2sus"
          class="w-100"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import Captcha from "../components/Captcha.vue";

export default {
  components: {
    Captcha,
  },
  data() {
    return {
      isLoading: false,
      verify_result: false,
      form: {
        name: "",
        tel: "",
        email: "",
        purpose: "",
      },
      buttons: [
        {
          name: "整合行銷",
          id: 1,
          clicked: false,
          children: ["品牌運營", "產品代操", "直播專場", "短影音創作"],
        },
        {
          name: "HCE 娛樂",
          id: 2,
          clicked: false,
          children: [
            "MCN、KOL合作",
            "多媒體創作",
            "企劃製作",
            "直播攝影棚租賃",
          ],
        },
        {
          name: "嗨吧APP",
          id: 3,
          clicked: false,
          children: [
            "嗨吧商城",
            "影音內容運營",
            "廣告投放、代理",
            "商業媒合服務",
          ],
        },
        {
          name: "嗨學院",
          id: 4,
          clicked: false,
          children: ["嗨學院數位課程", "企業培訓", "師資合作", "場地租賃"],
        },
      ],
      show_children: {
        name: "",
        item: [],
        clicked: "",
      },
    };
  },
  watch: {},
  methods: {
    get_verify_result: function (data) {
      this.verify_result = data;
    },
    replaceText: function () {
      this.form.tel = this.form.tel.replace(/\D/g, "");
    },
    send_form: function () {
      let regex = new RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/);
      if (!this.form.name) {
        alert("請輸入聯絡人");
      } else if (!this.form.tel || this.form.tel.length < 9) {
        alert("請輸入聯絡電話");
      } else if (!this.form.email || !regex.test(this.form.email)) {
        alert("請輸入正確的email格式");
      } else if (!this.verify_result) {
        alert("請輸入正確的驗證碼");
      } else {
        let purpose;
        if (this.show_children.parentName && this.show_children.clicked) {
          purpose =
            `
            <p>欲洽談業務：` +
            this.show_children.parentName +
            `/ ` +
            this.show_children.clicked +
            `</p>`;
        } else {
          purpose = `
            <p>欲洽談業務：用戶沒有選擇</p>
          `;
        }

        let text =
          purpose +
          `
          <p>聯絡人：` +
          this.form.name +
          `</p>
          <p>聯絡電話：` +
          this.form.tel +
          `</p>
          <p>E-mail：` +
          this.form.email +
          `</p>
          <p>需求說明：` +
          this.form.purpose +
          `</p>
        `;
        let vm = this;
        const data = new FormData();
        let loader = this.$loading.show({
          onCancel: this.yourMethodName,
          color: "#2575ed",
          loader: "spinner",
          width: 64,
          height: 64,
          backgroundColor: "#ffffff",
          opacity: 0.5,
        });
        data.append("content", text);
        const url = "https://hi8.tw/api/customer_service/contact_mail";
        this.axios
          .post(url, data)
          .then(function (response) {
            loader.hide();
            if (response.data.status) {
              vm.$router.go();
            }
            alert(response.data.msg);
          })
          .catch(function (error) {
            loader.hide();
            alert(error);
          });
      }
    },
    clickType: function (item) {
      this.buttons.forEach((e) => {
        e.clicked = false;
      });
      item.clicked = true;
      this.show_children.parentName = item.name;
      this.show_children.item = item.children;
      this.show_children.clicked = "";
    },
  },
};
</script>
<style lang="scss">
.contact {
  .banner_bg {
    background-image: url("~@/assets/contact/banner.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  .clicked {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    :focus {
      box-shadow: unset;
    }
  }
}
</style>
